var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-login"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"login-main"},[_c('pro-form',{attrs:{"label-width":"80px","label-suffix":":","label-position":"left","model":_vm.model,"fields":_vm.fields,"submitter":{
          submitButtonProps: {
            style: {
              display: 'block',
              width: '320px'
            },
            loading: _vm.loading
          },
          resetButtonProps: {
            style: {
              display: 'none'
            }
          },
          submitText: '登录'
        }},on:{"submit":_vm.submit}})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login__head"},[_c('h2',[_vm._v("评审专家登录")])])
}]

export { render, staticRenderFns }