<template>
  <div class="page-login">
    <div class="container">
      <div class="login__head">
        <h2>评审专家登录</h2>
      </div>
      <div class="login-main">
        <pro-form
          label-width="80px"
          label-suffix=":"
          label-position="left"
          :model="model"
          :fields="fields"
          :submitter="{
            submitButtonProps: {
              style: {
                display: 'block',
                width: '320px'
              },
              loading
            },
            resetButtonProps: {
              style: {
                display: 'none'
              }
            },
            submitText: '登录'
          }"
          @submit="submit"
        ></pro-form>
      </div>
    </div>
  </div>
</template>

<script>
import * as ConstantRouter from '@/constant/router'
import { localStore } from '@/utils/store'

export default {
  data() {
    return {
      loading: false,
      model: {
        email: '',
        password: ''
      },
      fields: [
        {
          type: 'input',
          formItemProps: {
            label: '账号',
            prop: 'email'
          },
          style: {
            width: '320px'
          },
          attributes: {
            placeholder: '请输入邮箱'
          }
        },
        {
          type: 'password',
          formItemProps: {
            label: '密码',
            prop: 'password'
          },
          style: {
            width: '320px'
          },
          attributes: {
            placeholder: '请输入密码'
          }
        }
      ],
      rules: {
        email: [{ required: true, message: '请输入邮箱', trigger: 'change' }],
        password: [{ required: true, message: '请输入密码', trigger: 'change' }]
      }
    }
  },
  methods: {
    async submit(data) {
      this.loading = true
      const [err, res] = await this.$serve.jsExpert.userLogin({
        data
      })
      this.loading = false
      if (err) return
      localStore.set('judge-token', res.token)
      localStore.set('judge-id', res.loginUserVo.id)
      this.$message.success('登录成功')
      this.$router.push({
        name: ConstantRouter.JUDGE
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.page-login {
  min-height: 100vh;
  overflow: hidden;
  background-image: linear-gradient(to right, #4facfe 0%, #00f2fe 100%);

  .container {
    width: 460px;
    margin: 60px auto;
    padding: 20px;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
    background: #fff;
    border-radius: 4px;
  }
}
.login__head {
  text-align: center;
  margin-bottom: 40px;
}
.login-main {
  margin: 0 auto;
}
</style>
